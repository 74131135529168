import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { ThinProfileSort } from '@/enums/ThinProfileSort';
import { QueryResources, _QueryResources, QueryResourcesInfinite, QueryResource } from '@/lib/datx-jsonapi-react';
import { Keywords } from '@/resources/Keywords';
import { ThinProfile } from '@/resources/ThinProfile';

export const queryThinProfilesByUserId = (id: string): _QueryResources<ThinProfile> => {
  return id
    ? [
        ThinProfile,
        {
          filter: {
            user_id: id,
          },
        },
      ]
    : null;
};

export const queryThinProfile = (id: string): QueryResource<ThinProfile> => {
  return id ? [ThinProfile, id] : null;
};

export const queryProfileKeywords = (id: string): QueryResources<Keywords> => {
  return id
    ? [
        `${ThinProfile.type}/${id}/keywords`,
        {
          params: [
            {
              key: 'page[size]',
              value: '15',
            },
            {
              key: 'page[number]',
              value: '1',
            },
          ],
        },
      ]
    : null;
};

export const queryThinProfileInfinite = (
  filters: Record<string, string | Array<string>>,
  pageSize = 16,
  sort = ThinProfileSort.LastName
): QueryResourcesInfinite<ThinProfile> => {
  return (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData?.links?.next) {
      return null;
    }

    const pageNumber = pageIndex + 1;

    return [
      ThinProfile,
      {
        filter: omitBy(filters, isNil),
        sort: ['avatar_existence', sort, '-created_at'],
        params: [
          {
            key: 'page[size]',
            value: pageSize.toString(),
          },
          {
            key: 'page[number]',
            value: pageNumber.toString(),
          },
        ],
      },
    ];
  };
};

export const queryLectureCommentProfiles = (userId: string | Array<string>): QueryResources<ThinProfile> => () =>
  userId && [ThinProfile, { filter: { user_id: userId } }];
